import store from "@/store/store.js";
import { optionsFunction } from "./_config";
import axios from "axios";

export const getAdyenSession = (payload) =>
  axios
    .post(
      `/api/payment/adyen/session`,
      {
        locale: payload.locale,
        offer_package_id: payload.offerId,
        return_url: payload.returnUrl,
      },
      optionsFunction()
    )
    .then((res) => res.data.data);

export const fetchPaypalPaymentCapture = (offer_package_id, order_id) =>
  axios
    .post(
      "/api/payment/paypal/capture",
      {
        offer_package_id: offer_package_id,
        order_id: order_id,
      },
      optionsFunction()
    )
    .then((res) => {
      // console.log("RESPONSE", res);
      return res;
    })
    .then((res) => res.data.data);

export const fetchAllPaymentOffers = () =>
  axios
    .post(`/api/payment/offers/list`, {}, optionsFunction())
    .then((res) => res.data.data);

export const fetchPaymentOffersByOfferType = (type) =>
  axios
    .post(
      `/api/payment/offers/getByType`,
      { offer_type: type },
      optionsFunction()
    )
    .then((res) => res.data.data);

export const fetchPaymentOffersByOfferId = (id) =>
  axios
    .post(`/api/payment/offers/getById`, { offer_id: id }, optionsFunction())
    .then((res) => res.data.data);

export const fetchPaymentOffersLobby = () =>
  axios
    .post(`/api/payment/offers/overview`, {}, optionsFunction())
    .then((res) => res.data.data);

export const fetchPaymentOffersRandom = () =>
  axios
    .post(`/api/payment/offers/random`, {}, optionsFunction())
    .then((res) => res.data.data);
