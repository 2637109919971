const GoogleTag = {
  install(Vue, options) {
    Vue.mixin({
      data() {
        return {};
      },

      computed: {
        currentUser: function () {
          return this.$store.getters["user/currentUser"];
        },
        isWriteable: function () {
          return this.$store.getters["externalScripts/getIndexedDBWriteable"];
        },
        scriptLoaded: function () {
          return this.$store.getters[
            "externalScripts/getGoogleTagScriptLoaded"
          ];
        },
        cookie: function () {
          return this.$store.getters["cookies/cookiePreferences"];
        },
        user: function () {
          return this.$store.getters["user/currentUser"];
        },
      },
      methods: {
        googleTagLog: function (obj) {
          if (!this.scriptLoaded) {
            return;
          }

          try {
            obj.user_id = this.user.id; //add user id
            dataLayer.push(obj);
          } catch (e) {}
        },

        googleTagLogNormalEvent: function (eventName) {
          this.googleTagLog({
            event: eventName,
          });
        },
        googleTagLogAdvancedEvent: function (eventObj) {
          this.googleTagLog(eventObj);
        },

        googleTagLogShopPurchased(payload) {
          this.googleTagLog({
            event: "purchase",
            ecommerce: {
              // google analytics 4 structure
              transaction_id: payload.transaction_reference,
              affiliation: payload.payment_method,
              tax: 0,
              value: payload.price,
              currency: payload.currency,
              items: [
                {
                  item_id: payload.offer_id,
                  item_name: payload.slug,
                  affiliation: payload.payment_method,
                  currency: payload.currency,
                  item_category: payload.itemCategory,
                  item_variant: payload.variant,
                  price: payload.price,
                  quantity: 1,
                },
              ],

              // google universal structure
              purchase: {
                actionField: {
                  id: payload.transaction_reference,
                  affiliation: payload.payment_method,
                  revenue: payload.price, // Total transaction value (incl. tax and shipping)
                },
                products: [
                  {
                    name: payload.itemName,
                    id: payload.offer_id,
                    price: payload.price,
                    brand: "Meine Krone",
                    category: payload.itemCategory,
                    variant: payload.variant,
                    quantity: 1,
                  },
                ],
              },
            },
          });
        },
      },
    });
  },
};

export default GoogleTag;
