<template>
  <div id="landing-page" class="d-flex flex-column justify-content-between">
    <div id="landing-container" class="mx-auto d-flex flex-column mb-5">
      <img
        id="app-logo"
        draggable="false"
        src="@/assets/img/logo/landing-crown.png"
        alt="Logo"
        class="mx-auto"
      />

      <div
        id="login-contents"
        class="w-100 h-100 d-flex flex-column justify-content-between position-relative px-5 pb-5"
      >
        <div id="app-title" class="mx-auto"></div>
        <p
          v-if="!registerPageTwo"
          class="w-100 text-center welcome-text text-uppercase bold mb-0"
        >
          {{ $t("login.web.register.welcome_message") }}
        </p>

        <LoginBox
          v-if="activeTab === 'login'"
          :error="loginError"
          @facebookConnected="handleFacebookLogin"
          @facebookLogout="handleFacebookLogout"
          @handleResetRequest="submitResetRequest"
          @stay="updateStayLoggedInPreference"
          @submitLogin="handleLogin"
          @toggle="handleTabtoggle('register')"
        />
        <RegisterBox
          v-else
          :friend-referrer="friendReferrer"
          :register-page-two="registerPageTwo"
          :error="registerError"
          @submitRegister="handleRegister"
          @facebookConnected="handleFacebookLogin"
          @facebookLogout="handleFacebookLogout"
          @page2="handleRegisterPageTwo"
          @back="handleBack"
          @toggle="handleTabtoggle('login')"
        />
      </div>
      <AlertWindow />
    </div>
    <LegalRow />
  </div>
</template>

<script>
import * as profileAPI from "@/API/profile.js";
import LoginBox from "@/components/landing/login-form.vue";
import LegalRow from "@/components/legal/legal-row.vue";
import RegisterBox from "@/components/landing/register-form.vue";
import AlertWindow from "@/components/base/alert-window.vue";
import checkLocale from "@/mixins/checkLocale.js";
import Bowser from "bowser";
import { getSettings } from "@/API/static-config";
import * as Cookies from "vue-cookies";

export default {
  name: "Login",
  components: {
    LoginBox,
    RegisterBox,
    AlertWindow,
    LegalRow,
  },
  mixins: [checkLocale],
  data() {
    return {
      registerPageTwo: false,
      resetEmail: "",
      loginError: false,
      registerError: false,
      errorMessage: "",
      activeTab: "register",
      instantSlotId: null,
    };
  },
  computed: {
    friendReferrer() {
      let referrer;
      if (document.referrer) {
        referrer = document.referrer;
      } else {
        referrer = "https://kleinekrone.page.link/";
      }
      return referrer + this.$store.state.auth.referrerToken;
    },
    lpBonusName() {
      return this.$store.state.auth.lpBonusName;
    },
  },

  created: function () {
    this.checkLocale();
    let welcomeCookie = Cookies.get("welcome");
    if (
      this.$store.getters["cookieManager/isCookieSet"]("welcome") ||
      this.$route.query.token === "loginActive" ||
      welcomeCookie
    ) {
      this.activeTab = "login";
    }
  },

  mounted() {
    this.$store.commit("setIsPageLoading", false);

    //set instant slot value
    if (this.$store.getters["auth/isLoggedIn"]) {
      const userId = this.$store.getters["user/currentUser"].id;
    } else {
      getSettings().then((res) => {
        let gameIdFromLink = this.$store.state.gameplay.redirectGame;
        if (gameIdFromLink) {
          this.instantSlotId = gameIdFromLink;
          this.$store.commit("gameplay/setRedirectGame", null);
        } else {
          if (res.data && res.data["newuser.instant_slot"]) {
            this.instantSlotId = res.data["newuser.instant_slot"];

            //save setting config in store
            let configData = {};
            configData.settings = res.data;
            this.$store.commit("setGeneralConfig", configData);
          }
        }
      });
    }

    this.checkForLandingPageBonus();
  },
  methods: {
    checkForLandingPageBonus() {
      const lpName = this.$route.query.lp_redirect;
      if (lpName) {
        this.$store.commit("auth/setLpBonusName", lpName);

        //remove parameters from uri
        this.$router
          .replace(this.$route.fullPath.split("?")[0])
          .catch(() => {});
      }
    },
    handleLogin: function (email, password) {
      this.$store
        .dispatch("auth/logUserIn", { email, password })
        .then(() => {
          this.firebaseSetUser("LEGACY");
          this.playBackgroundMusic();
          this.safariRefresh();
          this.maybeRedirectToGame();
        })
        .catch((err) => {
          if (err.data) {
            if (err.data.message === "The given data was invalid.") {
              this.error = true;
              this.$store.commit("popups/setAlertBannerContent", {
                alertText: this.$t("login.login_failure"),
                type: "danger",
                variant: "danger",
                alertHeading: this.$t("alerts.title.error"),
              });
            } else {
              this.displayErrorMessage();
            }
          } else {
            this.displayErrorMessage();
          }
        });
    },
    handleFacebookLogin(oAuthToken) {
      this.$store
        .dispatch("auth/facebookLogin", oAuthToken)
        .then((res) => {
          if (res.data.data.login) {
            this.$store.commit("auth/auth_success");
            this.playBackgroundMusic();
            this.safariRefresh();
            this.maybeRedirectToGame();
          } else {
            this.proceedToLobby();
            this.requestEmailConfirm();
          }
        })

        .catch((e) => {
          console.log(e);
          if (e.data.data.email) {
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: e.data.data.email[0],
              type: "danger",
              variant: "danger",
              alertHeading: this.$t("alerts.title.error"),
            });
          } else {
            this.displayErrorMessage();
          }
        });
    },

    handleFacebookLogout() {
      this.$store.dispatch("destroyStore").then(this.$router.push("/"));
    },
    handleRegister(userData) {
      this.$store
        .dispatch("auth/registerUser", userData)
        .then(() => {
          this.proceedToLobby();
          this.$store.commit("auth/setReferrerToken", "");
          this.firebaseSetUser("LEGACY");
          let googleTagObj = {
            event: "Registrierung",
            label: "Web",
          };
          if (this.lpBonusName) {
            googleTagObj.exit_intent = this.lpBonusName;
          }
          this.googleTagLogAdvancedEvent(googleTagObj);
        })
        .then(() => {
          this.requestEmailConfirm();
        })
        .catch((e) => {
          this.registerError = true;
          console.log("ERROR", e);
          if (e.data.id) {
            if (e.data.id === "Validation failed") {
              if (e.data.details.email) {
                this.$store.commit("popups/setAlertBannerContent", {
                  alertText: e.data.details.email[0],
                  alertHeading: this.$t("alerts.title.error"),
                  type: "danger",
                  variant: "danger",
                });
              } else if (e.data.details.name) {
                this.$store.commit("popups/setAlertBannerContent", {
                  alertText: e.data.details.name[0],
                  alertHeading: this.$t("alerts.title.error"),
                  type: "danger",
                  variant: "danger",
                  dismissMessage: "ok-name-error",
                });
              } else {
                this.displayErrorMessage();
                console.log(e);
              }
            } else if (e.data.message) {
              this.$store.commit("popups/setAlertBannerContent", {
                alertText: e.data.message,
                alertHeading: this.$t("alerts.title.error"),
                type: "danger",
                variant: "danger",
              });
            }
          } else {
            this.displayErrorMessage();
          }
        });
    },
    proceedToLobby() {
      this.$store.commit("auth/auth_success");
      this.playBackgroundMusic();

      //go to instant slot, if available
      if (this.instantSlotId) {
        this.$router.push({
          name: "game",
          params: { gameId: this.instantSlotId },
        });
      }
    },
    maybeRedirectToGame() {
      if (this.$store.state.gameplay.redirectGame) {
        let redirectGameId = parseInt(this.$store.state.gameplay.redirectGame);
        this.$router.push({
          name: "game",
          params: { gameId: redirectGameId },
        });
        this.$store.commit("gameplay/setRedirectGame", null);
      } else return;
    },

    safariRefresh() {
      const browser = Bowser.getParser(window.navigator.userAgent).getBrowser()
        .name;
      if (browser === "Safari") {
        window.location.reload();
      }
    },
    requestEmailConfirm() {
      profileAPI.confirmEmail().catch((e) => console.log(e));
    },
    updateStayLoggedInPreference(boolean) {
      this.$store.commit("auth/setStayLoggedIn", boolean);
    },
    submitResetRequest(email) {
      this.resetEmail = email;
      profileAPI
        .requestReset(email)
        .then((res) => {
          this.$store.commit("popups/setAlertBannerContent", {
            alertText: this.$t("login.password_forgotten.reset_email_message", {
              0: this.resetEmail,
            }),
            alertHeading: "Ok!",
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.data) {
            this.errorMessage = e.data.message;
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: this.errorMessage,
              alertHeading: this.$t("alerts.title.error"),
              variant: "danger",
              type: "danger",
            });
          } else this.displayErrorMessage();
        });
    },
    handleRegisterPageTwo() {
      this.registerPageTwo = true;
    },
    handleBack() {
      this.registerPageTwo = false;
    },
    handleTabtoggle(tab) {
      this.activeTab = tab;
      if (tab === "login") {
        this.registerPageTwo = false;
      }
    },
  },
};
</script>
<style lang="scss">
#landing-page {
  #landing-container {
    height: 600px;
    width: 620px;
    position: relative;
    background-color: transparent;
    margin: 0;
    @media (max-width: 800px) {
      width: 100%;
    }

    // This allows for an overflowing background image
    &::before {
      content: "";
      position: absolute;
      width: 740px;
      height: 720px;
      left: -60px;
      top: -60px;
      background: url(~@/assets/img/backgrounds/login-window-back.svg)
        center/100% 100% no-repeat;
      -webkit-transform: translate3d(0, 0, 0);
      @media (max-width: 800px) {
        width: 120%;
        left: -10%;
        top: -10.5%;
        height: 750px;
      }
    }

    #app-logo {
      width: 271.9px;
      height: 89.1px;
      z-index: 2;
      margin-top: -4px;
    }

    #login-contents {
      #app-title {
        height: 48px;
        width: 90%;
        background: url(~@/assets/img/logo/title-meine.png) left/48% 100%
            no-repeat,
          url(~@/assets/img/logo/title-krone.png) right/48% 100% no-repeat;
      }
      .welcome-text {
        font-size: 28px;
        color: #fff8b7;
      }
    }
  }

  .landing-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .info-text {
      font-size: 16px;
      color: #8e8e8e;
    }

    .toggle-text {
      font-size: 21px;
      cursor: pointer;

      a {
        color: $hightlight-yellow !important;
        &:hover {
          text-decoration: underline !important;
        }
      }
    }

    .pseudo-label a {
      color: $hightlight-yellow !important;
      font-family: Ubuntu-Bold;
    }

    .pseudo-label {
      font-size: 14px;
    }

    .v-facebook-login {
      background-color: transparent;
      margin: auto;
      cursor: pointer;
      border-radius: 8px;
      min-width: 240px;
    }

    .button-facebook-lg {
      height: 48px;
      line-height: 1.1;
    }

    .ok-button-green {
      height: 48px;
      color: white;
      font-size: 22px;
      &:disabled {
        filter: grayscale(100);
        pointer-events: none;
      }
      @media (max-width: 380px) {
        width: 100%;
      }
    }

    .footnote {
      font-size: 9px;
      a {
        color: #007bff !important;
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }
  .dark.frc-captcha * {
    color: rgba(255, 248, 183);
  }

  & {
    .dark.frc-captcha {
      color: #fff;
      background-color: #081829;
      border-color: #2edfea;
    }
  }

  .dark.frc-captcha button {
    background-color: #081829;
    color: #2edfea;
  }
}
</style>
