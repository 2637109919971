const getDefaultState = () => {
  return {
    scriptLoadedGoogleTag: false,
    scriptLoadedHotjar: false,
    scriptLoadedFirebase: false,
    indexedDBWriteable: false,
  };
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setFirebaseScriptLoaded(state, payload) {
    state.scriptLoadedFirebase = payload;
  },
  setGoogleTagScriptLoaded(state, payload) {
    state.scriptLoadedGoogleTag = payload;
  },

  setHotjarScriptLoaded(state, payload) {
    state.scriptLoadedHotjar = payload;
  },
  setIndexedDBWriteable(state, payload) {
    state.indexedDBWriteable = payload;
  },
};

const getters = {
  getFirebaseScriptLoaded: (state) => state.scriptLoadedFirebase,
  getGoogleTagScriptLoaded: (state) => state.scriptLoadedGoogleTag,
  getHotjarScriptLoaded: (state) => state.scriptLoadedHotjar,
  getIndexedDBWriteable: (state) => state.indexedDBWriteable,
};
const actions = {};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
