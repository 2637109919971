<template>
  <b-modal
    id="modal-mail-confirm"
    modal-class="alert-window"
    :hide-footer="true"
  >
    <template #modal-header>
      <div class="d-flex flex-column align-items-center">
        <div id="newsletter-icon" class="bg-img"></div>

        <div
          role="button"
          href="#"
          class="close-button"
          @click="$bvModal.hide('modal-mail-confirm')"
        ></div>
        <DecoCorners color-variant="blue" />
        <div class="super-headline3 bold">
          {{ $t("alerts.email_confirm.prompt_instant.heading") }}
        </div>
      </div>
    </template>
    <template slot="default">
      <div class="modal-contents position-relative">
        <p class="modal-prompt mt-3">
          {{ $t("alerts.email_confirm.prompt_instant.body") }}
        </p>
        <p class="bold">
          {{ $t("alerts.email_confirm.your_reward") }}
        </p>
        <div
          id="reward-display"
          class="d-inline-flex align-items-center justify-content-around bg-img"
        >
          <img src="@/assets/img/common/coin1.png" draggable="false" />
          <span class="blue bold">{{ formatNumber(confirmReward) }}</span>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import DecoCorners from "@/components/base/deco-corners.vue";
export default {
  name: "ModalMailConfirm",
  components: { DecoCorners },
  computed: {
    confirmReward() {
      return this.$store.state.generalConfig.settings["mail.verify.reward"]
        .gold;
    },
  },
};
</script>
<style lang="scss">
#modal-mail-confirm {
  margin-top: 100px;
  .close-button {
    right: -30px;
    top: -29px;
    width: 60px;
    height: 60px;
  }
  .modal-header {
    border: none !important;
    justify-content: center;
    text-transform: uppercase;
    font-size: 44px;
    .super-headline3 {
      background-image: linear-gradient(to bottom, #f7ffff, #ffee51);
      font-family: "Ubuntu", sans-serif !important;
    }
  }
  .modal-dialog {
    max-width: 734px;
  }
  .modal-content {
    color: white;
    text-align: center;
    background-color: transparent;
    background-image: linear-gradient(#205f72, #050d1c),
      linear-gradient(#050d1c, #205f72);
    border: double 1px transparent;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-size: 100% 100%;
  }

  .modal-contents {
    width: 100%;

    min-height: 128px;
    .super-headline3 {
      font-size: 28.8px;
      background-image: linear-gradient(to bottom, #f7ffff, #ffee51, #8b5a00);
      filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.5))
        drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
    }
    .modal-prompt {
      padding: 0 100px;
      font-size: 24px;
    }
    #reward-display {
      width: 383px;
      height: 62px;
      background-image: url(~@/assets/img/common/reward-display.svg);
      margin-bottom: 96px;
      img {
        height: 47px;
      }
      span {
        font-size: 48px;
        text-shadow: 0 0 10px rgba(77, 229, 255, 0.53),
          0 0 2px rgba(0, 0, 0, 0.5), 0 0 2px rgba(0, 0, 0, 0.8);
      }
    }
  }
  #newsletter-icon {
    width: 265px;
    height: 165px;
    background-image: url(~@/assets/img/common/three-coins.png);
    z-index: 10;
  }
}
</style>
