const Firebase = {
  install(Vue, options) {
    Vue.mixin({
      data() {
        return {};
      },

      computed: {
        currentUser: function () {
          return this.$store.getters["user/currentUser"];
        },
        isWriteable: function () {
          return this.$store.getters["externalScripts/getIndexedDBWriteable"];
        },
        scriptLoaded: function () {
          return this.$store.getters["externalScripts/getFirebaseScriptLoaded"];
        },
      },
      methods: {
        firebaseLogEvent: function (eventName, payload = "") {
          if (!this.isWriteable) {
            return;
          }
          if (!this.scriptLoaded) {
            return;
          }
          firebase.analytics().logEvent(eventName, payload);
        },
        firebaseSetScreen: function (screenName, screenClass) {
          if (!this.isWriteable) {
            return;
          }
          if (!this.scriptLoaded) {
            return;
          }
          if (typeof firebase === "undefined") {
            return false;
          }

          firebase
            .analytics()
            .setCurrentScreen(screenName, { screen_class: screenClass });
        },
        firebaseSetUser: function (methodName) {
          if (!this.isWriteable) {
            return;
          }
          if (!this.scriptLoaded) {
            return;
          }
          if (typeof firebase === "undefined") {
            return false;
          }

          firebase.analytics().setUserId(this.currentUser.id);
          firebase
            .analytics()
            .setUserProperties({ method: methodName, source: "WEB" });
        },

        firebaseClickItemHeader: function (itemId) {
          this.firebaseLogEvent("select_content", {
            content_type: "btn",
            item_id: itemId,
            item_category: "HEADER",
          });
        },
        firebaseClickItemFooter: function (itemId) {
          this.firebaseLogEvent("select_content", {
            content_type: "btn",
            item_id: itemId,
            item_category: "FOOTER",
          });
        },
        firebaseClickItemMenu: function (itemId) {
          this.firebaseLogEvent("select_content", {
            content_type: "btn",
            item_id: itemId,
            item_category: "MENU",
          });
        },
        firebaseClickItemTreasury: function (itemId) {
          this.firebaseLogEvent("select_content", {
            content_type: "btn",
            item_id: itemId,
            item_category: "TREASURY",
          });
        },
        firebaseClickItemShop: function (itemId) {
          this.firebaseLogEvent("select_content", {
            content_type: "btn",
            item_id: itemId,
            item_category: "SHOP",
          });
        },
        firebaseClickItemOffer: function (itemId) {
          this.firebaseLogEvent("select_content", {
            content_type: "btn",
            item_id: itemId,
            item_category: "OFFER",
          });
        },
        firebaseViewOffer: function (promotion_name) {
          this.firebaseLogEvent("view_promotion", {
            promotion_name: promotion_name,
          });
        },
        firebaseClickItemProfile: function (itemId) {
          this.firebaseLogEvent("select_content", {
            content_type: "btn",
            item_id: itemId,
            item_category: "PROFILE",
          });
        },
        firebasePurchase(payload) {
          this.firebaseLogEvent("purchase", {
            item_id: payload.offer_id,
            value: payload.price,
            currency: payload.currency,
            payment_method: payload.payment_method,
          });
        },
        firebaseVirtualCurrencyDrain: function (
          itemName,
          currency,
          value,
          category
        ) {
          this.firebaseLogEvent("spend_virtual_currency", {
            item_name: itemName,
            virtual_currency_name: currency,
            value: value,
            item_category: category,
          });
        },
        firebaseOnScroll: function (source, destination, distance) {
          this.firebaseLogEvent("web_scroll", {
            source: source,
            destination: destination,
            distance: distance,
          });
        },
      },
    });
  },
};

export default Firebase;
