const getDefaultState = () => {
  return {
    shopChosenPackage: null,
    lobbyOffer: null,
    specialOffer: null,
    offerId: "",
    autoShowSpecialOffer: true,
    redirectResult: null,
    sessionId: null,
    transactionId: null,
    paymentCompleted: false,
    paymentError: false,
    paymentStatus: "",
    adyenData: null,
    paymentMethod: "",
  };
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  setShopChosenPackage(state, payload) {
    state.shopChosenPackage = payload;
  },
  setLobbyOffer(state, payload) {
    state.lobbyOffer = payload;
  },
  setSpecialOffer(state, payload) {
    state.specialOffer = payload;
  },
  setPaymentMethod: (state, string) => (state.paymentMethod = string),
  setPaymentCompleted: (state, boolean) => (state.paymentCompleted = boolean),
  setPaymentError: (state, boolean) => (state.paymentError = boolean),
  setPaymentStatus: (state, string) => (state.paymentStatus = string),
  setTransactionId: (state, number) => (state.transactionId = number),
  setAdyenData: (state, payload) => (state.adyenData = payload),
  setOfferId: (state, string) => (state.offerId = string),
  setAutoShowSpecialOffer: (state, boolean) =>
    (state.autoShowSpecialOffer = boolean),
  setRedirectResult: (state, payload) => (state.redirectResult = payload),
  setSessionID: (state, payload) => (state.sessionId = payload),
  clearLastPayment: (state) => {
    state.redirectResult = null;
    state.sessionId = null;
    state.transactionId = null;
    state.shopChosenPackage = null;
    state.adyenData = null;
    state.paymentCompleted = false;
    state.paymentError = false;
    state.paymentStatus = "";
  },
};

const getters = {
  getShopChosenPackage: (state) => state.shopChosenPackage,
  getLobbyOffer: (state) => state.lobbyOffer,
  getSpecialOffer: (state) => state.specialOffer,
  getSpecialOfferTime: (state) => {
    if (!state.specialOffer) return null;
    let time = new Date(state.specialOffer.valid_until).getTime();
    return time;
  },
  getShowBuyResult: (state) =>
    state.transactionId && state.sessionId && state.redirectResult,
};
const actions = {
  resetShopState({ commit }) {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
