<template>
  <div translate="no">
    <h1>Impressum</h1>
    <h2>
      Das Social Casino „Meine Krone“ mit seinen Subsites und mobilen
      Applikationen ist ein Angebot der Glücksfaktor 7 GmbH
    </h2>

    <p>
      <b>Glücksfaktor 7 GmbH</b><br />Lange Str. 15<br />29664 Walsrode<br />Telefon:
      05161 7874916
    </p>
    <p>
      <b>Mailadresse: </b>
      <a href="mailto:info@gluecksfaktor7.com">info(at)gluecksfaktor7.com</a>
    </p>
    <p><b>Vertreten durch Geschäftsführer:</b> Torben Ludwig</p>
    <p>
      Registergericht: Walsrode<br />Registernummer: HRB 212241<br />USt.-Id.-Nr.:
      DE370277017
    </p>
    <p>
      <b>Online-Streitbeilegung </b><br />
      Die Europäische Kommission stellt eine Plattform zur
      Online-Streitbeilegung bereit, die Sie unter
      <a href="https://ec.europa.eu/consumers/odr"
        >https://ec.europa.eu/consumers/odr</a
      >
      finden. Die Glücksfaktor 7 GmbH ist nicht verpflichtet, an
      Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
      teilzunehmen und nimmt daher auch nicht teil.
    </p>
    <p>
      <b>Haftungsausschluss</b><br />
      Wir sind als Inhaltsanbieter für die eigenen Inhalte, die wir auf unserer
      Website bereithalten, verantwortlich. Wir halten an einigen Stellen durch
      Verlinkungen außerdem fremde Inhalte bereit. Diese fremden Inhalte wurden
      bei der erstmaligen Link-Setzung daraufhin überprüft, ob durch sie
      möglicherweise eine zivilrechtliche oder strafrechtliche Verantwortung
      ausgelöst wird. Es ist jedoch nicht auszuschließen, dass die Inhalte im
      Nachhinein von den jeweiligen Anbietern verändert werden. Wir überprüfen
      die Fremdinhalte nicht ständig auf Veränderungen, die eine
      Verantwortlichkeit neu begründen könnten. Sollten Sie der Ansicht sein,
      dass die verlinkten externen Seiten gegen geltendes Recht verstoßen oder
      sonst unangemessene Inhalte haben, so teilen Sie uns dies bitte mit.
    </p>
    <p>
      <b>Urheberrecht</b><br />
      Der Inhalt dieser Seiten ist urheberrechtlich geschützt. Die angebotenen
      Inhalte sind nur für den privaten Gebrauch bestimmt. Jede weitergehende
      Nutzung der Inhalte ohne Zustimmung der Glücksfaktor 7 GmbH, insbesondere
      jede Form kommerzieller Nutzung ist untersagt.
    </p>
  </div>
</template>

<script>
export default {
  name: "ImprintTextDE",
};
</script>
