<template>
  <form
    v-if="!registerPageTwo"
    class="landing-form"
    @submit.prevent="submitName"
  >
    <label class="reg-label mb-1" for="userInput">{{
      $t("login.web.register_username_label")
    }}</label>
    <input
      id="userInput"
      v-model="userInput"
      name="userInput"
      class="form-control"
      :class="{ error: !usernameLength && displayNameFeedback }"
      required
      autofocus="true"
      autocomplete="username"
    />
    <p
      v-if="!displayNameFeedback || usernameLength"
      class="w-100 mb-0 text-center info-text"
    >
      {{ $t("login.web.register_username_info") }}
    </p>
    <p
      v-else-if="!usernameLength && displayNameFeedback"
      class="w-100 mb-0 text-center info-text text-danger"
    >
      {{ $t("login.web.register.invalid_feedback.username") }}
    </p>
    <p class="blue medium mb-0 mt-3 text-center toggle-text">
      <i18next
        :translation="$t('login.web.register.login_message')"
        @click="$emit('toggle')"
      >
        <template #link>
          <a href="#" @click.prevent="$emit('toggle')">{{
            $t("login.web.register.here_link")
          }}</a></template
        >
      </i18next>
    </p>

    <div class="w-100 d-inline-flex align-items-center justify-content-center">
      <v-facebook-login
        v-if="$store.getters.facebookEnabled"
        :class="[
          !displayFbButton || !$store.getters.facebookEnabled
            ? 'd-none'
            : 'd-flex',
        ]"
        :app-id="facebookAppId"
        class="button-facebook-lg w-50 me-1"
        type="button"
        name="facebook-register-submit"
        version="v12.0"
        @login="handleFbRegister"
        @logout="$emit('facebookLogout')"
        @sdk-init="displayFbButton = true"
        ><template slot="login">
          <div class="text-center">
            {{ $t("login.web.facebook_button.register") }} *
          </div></template
        >
        ><template slot="logout">
          <div class="text-center">
            {{ $t("login.web.facebook_button.logout") }}
          </div></template
        >
        <template slot="working">
          <div class="text-center">
            {{ $t("login.web.facebook_button.waiting") }}
          </div></template
        >
      </v-facebook-login>

      <button
        type="submit"
        name="username-submit"
        class="ok-button-green w-50 ms-1"
        :disabled="disabledButton === 'username-submit'"
      >
        {{ $t("login.web.register_next") }}
      </button>
    </div>
    <div class="text-white footnote mt-3">
      <i18next
        v-if="displayFbButton"
        :translation="$t('login.web.register.facebook_footnote')"
      >
        <template #link>
          <router-link to="/privacy"
            >{{ $t("legal.privacy_statement") }}
          </router-link></template
        >
        <template #mail>
          <a href="mailto:datenschutz@gluecksfaktor7.com"
            >datenschutz@gluecksfaktor7.com</a
          ></template
        >
      </i18next>
    </div>
  </form>
  <form
    v-else
    novalidate="true"
    class="landing-form"
    @submit.prevent="submitRegister"
  >
    <div class="form-group mb-3 mt-3">
      <input
        v-model="email"
        type="email"
        name="register-email"
        autofocus="true"
        class="form-control"
        :class="{
          error: !validEmail && displayOtherFeedback,
        }"
        required
        :placeholder="$t('login.web.email_placeholder')"
        autocomplete="register-email"
      />
      <p
        v-if="!validEmail && displayOtherFeedback"
        class="w-100 to-center-abs small m-0 text-center text-danger"
      >
        {{ $t("login.web.register.invalid_feedback.email") }}
      </p>
    </div>
    <div class="form-group mb-3">
      <input
        v-model="password"
        name="register-password"
        type="password"
        class="form-control"
        :class="{
          error: !minLength && displayOtherFeedback,
        }"
        required
        :placeholder="$t('login.web.register.choose_password')"
        autocomplete="register-password"
      />
      <p
        v-if="!minLength && displayOtherFeedback"
        class="w-100 to-center-abs small m-0 text-center text-danger"
      >
        {{ $t("profile.change_password.invalid_feedback.length", { 0: 6 }) }}
      </p>
    </div>
    <div class="form-group">
      <input
        v-model="passwordRepeat"
        name="register-password-repeat"
        type="password"
        class="form-control"
        :class="{ error: !passwordMatch && displayOtherFeedback }"
        required
        :placeholder="$t('login.web.password_repeat_placeholder')"
        autocomplete="register-password"
      />
      <p
        v-if="!passwordMatch && displayOtherFeedback"
        class="w-100 to-center-abs small m-0 text-center text-danger"
      >
        {{ $t("profile.change_password.invalid_feedback.match") }}
      </p>
    </div>

    <div class="w-100 d-flex align-items-center">
      <div class="w-50">
        <div class="form-group mb-0">
          <label
            class="modern-check-label mb-0"
            for="newsletter-check"
            tabindex="0"
            role="checkbox"
            @keydown.space.prevent="newsletterCheck = !newsletterCheck"
          >
            <span class="pseudo-label d-block">
              <i18next :translation="$t('login.web.register.newsletter_new')">
                <template #0>
                  <span class="bold" place="0">
                    {{ newsletterGold }}
                  </span></template
                >
                *
              </i18next></span
            >
            <input
              id="newsletter-check"
              v-model="newsletterCheck"
              type="checkbox"
              tabindex="-1"
              checked /><span class="blue-checkmark"></span
          ></label>
        </div>
        <div class="form-group mb-0">
          <label
            class="modern-check-label mb-0"
            name="checkagb"
            for="tac-check"
            tabindex="0"
            role="checkbox"
            @keydown.space.prevent="agbCheck = !agbCheck"
          >
            <div
              class="pseudo-label"
              :class="{ error: !agbCheck && displayOtherFeedback }"
              v-html="localeTac"
            ></div>
            <input
              id="tac-check"
              v-model="agbCheck"
              type="checkbox"
              tabindex="-1" /><span class="blue-checkmark"></span
          ></label>
        </div>
        <div class="form-group mb-0">
          <label
            class="modern-check-label mb-0"
            name="checkage"
            for="age-check"
            tabindex="0"
            role="checkbox"
            @keydown.space.prevent="ageCheck = !ageCheck"
          >
            <div
              class="pseudo-label"
              :class="{ error: !ageCheck && displayOtherFeedback }"
            >
              {{ $t("register.over_18") }}
            </div>
            <input
              id="age-check"
              v-model="ageCheck"
              type="checkbox"
              tabindex="-1" /><span class="blue-checkmark"></span
          ></label>
        </div>
      </div>

      <button
        type="submit"
        name="register-submit"
        class="ok-button-green play-now-button w-50 text-center"
        :disabled="disabledButton === 'register-submit'"
      >
        {{ $t("login.web.register.play_now_button") }}
      </button>
    </div>
    <div class="w-100 text-center">
      <a
        href="#"
        class="blue bold mx-auto text-normal"
        @click.prevent="handleBack"
        >{{ $t("support.faq.back") }}</a
      >
    </div>
    <div class="text-white footnote">
      <i18next :translation="$t('login.web.register.newsletter_footnote')">
        <template #link>
          <router-link to="/privacy"
            >{{ $t("legal.privacy_statement") }}
          </router-link></template
        >
      </i18next>
    </div>
    <vue-friendly-captcha
      :sitekey="friendlySitekey"
      class="mx-auto"
      :dark="true"
      :language="$store.state.locale"
      @started="disabledButton = 'register-submit'"
      @done="handleCaptchaDone"
    />
  </form>
</template>

<script>
import { VFBLogin as VFacebookLogin } from "vue-facebook-login-component";
import { checkNameForBlacklist } from "@/API/auth.js";
import VueFriendlyCaptcha from "@somushq/vue-friendly-captcha";
export default {
  name: "Login",
  components: { VFacebookLogin, VueFriendlyCaptcha },
  props: {
    friendReferrer: {
      type: String,
      default: "",
    },
    registerPageTwo: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      userInput: "",
      email: "",
      password: "",
      passwordRepeat: "",
      newsletterCheck: false,
      agbCheck: false,
      ageCheck: false,
      displayNameFeedback: false,
      displayEmailFeedback: false,
      displayOtherFeedback: false,
      facebookAppId: process.env.VUE_APP_FACEBOOK_APP_ID,
      friendlySitekey: process.env.VUE_APP_FRIENDLY_CAPTCHA_SITEKEY,
      disabledButton: "",
      displayFbButton: false,
      captchaSolution: "",
    };
  },
  computed: {
    lpBonusName() {
      return this.$store.state.auth.lpBonusName;
    },
    usernameLength() {
      return this.userInput.length >= 3 && this.userInput.length <= 40;
    },
    minLength() {
      return this.password.length >= 6;
    },
    passwordMatch() {
      return this.password === this.passwordRepeat;
    },
    validEmail() {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.email);
    },

    localeTac() {
      if (this.$store.state.locale === "en") {
        return "In the game, our <a href='/tac?locale=en'>TAC</a> and <a href='/privacy?locale=en'>Privacy Policy</a> are valid.";
      } else {
        return "Im Spiel gelten unsere  <a href='/tac?locale=de'>AGB</a> und unsere <a href='/privacy?locale=de'>Datenschutzerklärung</a>.";
      }
    },
    everythingSet() {
      return (
        this.validEmail &&
        this.minLength &&
        this.agbCheck &&
        this.ageCheck &&
        this.passwordMatch &&
        this.captchaSolution !== ""
      );
    },

    newsletterGold() {
      let settings = this.$store.state.generalConfig.settings;
      if (settings["user.newsletter.register.gold"]) {
        return this.formatNumber(
          settings["user.newsletter.register.gold"],
          true
        );
      }

      return this.formatNumber(10000000, true);
    },
  },
  watch: {
    error() {
      this.disabledButton = "";
    },
  },
  mounted() {
    this.$store.commit("setIsPageLoading", false);
    this.$root.$on("ok-name-error", () => {
      this.userInput = "";
    });
  },
  methods: {
    handleCaptchaDone(e) {
      this.captchaSolution = e;
      this.disabledButton = "";
    },
    handleCaptchaError(e) {
      this.captchaSolution = "";
      console.log("Friendly Captcha Error", e);
    },
    submitRegister(e) {
      this.disabledButton = "register-submit";
      if (this.everythingSet) {
        let userData = {
          name: this.userInput,
          email: this.email,
          password: this.password,
          newsletter: this.newsletterCheck,
          referrer: this.friendReferrer,
          "frc-captcha-solution": this.captchaSolution,
        };

        //use code from landing page and set bonus
        if (this.lpBonusName) {
          userData.source = this.lpBonusName;
          userData.medium = "exit_intend";
        }

        this.$emit("submitRegister", userData);
      } else {
        this.displayOtherFeedback = true;
        this.disabledButton = "";
      }
    },
    submitName() {
      this.disabledButton = "username-submit";
      if (this.usernameLength) {
        const locale = this.$store.getters.getLocale;
        const localeQuery = locale + "_" + locale.toUpperCase();
        checkNameForBlacklist(this.userInput, localeQuery)
          .then((res) => {
            this.googleTagLogNormalEvent("Next");
            this.$emit("page2");
          })
          .catch((e) => {
            console.log(e);
            this.userInput = "";
            this.disabledButton = "";
            if (e.data.details.name[0]) {
              this.$store.commit("popups/setAlertBannerContent", {
                alertText: e.data.details.name[0],
                alertHeading: this.$t("alerts.title.error"),
                variant: "danger",
                type: "danger",
              });
            } else {
              this.$store.commit("popups/setAlertBannerContent", {
                alertText: this.$t("user.error.name_blacklisted"),
                alertHeading: this.$t("alerts.title.error"),
                variant: "danger",
                type: "danger",
              });
            }
          });
      } else {
        this.displayNameFeedback = true;
        this.disabledButton = "";
      }
    },
    handleFbRegister(e) {
      if (e.status === "connected") {
        this.firebaseSetUser("FB");
        this.googleTagLogAdvancedEvent({
          event: "Registrierung",
          label: "Facebook",
        });
        this.$emit("facebookConnected", e.authResponse.accessToken);
      } else {
        console.log(e);
      }
    },
    handleBack() {
      this.disabledButton = "";
      this.$emit("back");
    },
  },
};
</script>
<style scoped lang="scss">
.landing-form {
  .reg-label {
    font-size: 16px;
  }
}
</style>
